<template>
    <div>
    </div>
</template>

<script>
import _ from "lodash";
export default {
    data(){
        return{
            accessToken: "",
        }
    },
    methods:{
        async login(){
            await this.$store.dispatch('getUser').then(res => {
                this.$store.commit('setShowLoader', false)
                if(res == true){
                    this.$router.push('/')
                }
                else{
                    this.$router.push('/login')
                }
            })
        }
    },
    mounted(){
        this.$store.commit('setShowLoader', true)
        this.accessToken = this.$router.currentRoute.query.access_token
        if(_.size(this.accessToken)){
            localStorage.setItem('buyer_access_token', this.accessToken)
            this.login()
        }
        else{
            this.$store.commit('setShowLoader', false)
            this.$router.push('/login')
        }
    }
}
</script>